<template>
  <div class="StatusPage">
    <status-page-header
      :status-page="statusPage"
      :status-page-language="statusPageLanguage"
      :logoText="logoText"
      :logo="logo"
      :headerImage="headerImage"
      :isDarkenHeaderImage="!!isDarkenHeaderImage"
      :logoStyles="logoStyles"
      :logoLink="logoLink"
    />
    <div class="container">
      <router-view
        :statusPage="statusPage"
        :status-page-language="statusPageLanguage"
      />

      <status-page-footer
        :status-page="statusPage"
        :status-page-language="statusPageLanguage"
      />
    </div>
  </div>
</template>

<script>
import StatusPageHeader from './StatusPageHeader.vue'
import StatusPageFooter from './StatusPageFooter.vue'

import statusPagesApi from '@/api/statusPagesApi.js'
import dayjs from 'dayjs'

export default {
  components: {
    StatusPageHeader,
    StatusPageFooter
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      monitors: [],
      logoText: null,
      logo: null,
      headerImage: null,
      favicon: null,
      isDarkenHeaderImage: false,
      logoStyles: null,
      logoLink: null,
      statusPageLanguage: {},

      since: dayjs().subtract(90, 'day').startOf('day'),
      till: dayjs().endOf('day')
    }
  },

  created () {
    // this.loadSnapshots()
    // await this.setStyles()

    if (this.statusPage && this.statusPage.status_pages_language) {
      this.statusPageLanguage = JSON.parse(this.statusPage.status_pages_language.json_data)
    }

    if (this.statusPageLanguage?.date_and_time_language?.type) {
      require(`dayjs/locale/${this.statusPageLanguage.date_and_time_language.type}`)
      dayjs.locale(this.statusPageLanguage.date_and_time_language.type)
    }
  },

  methods: {
    async setStyles () {
      let styles = {}
      let form = JSON.parse(localStorage.getItem('status_page_preview'))
      if (form && this.$route.query.preview && form.slug === this.statusPage.slug) {
        styles = {
          ...form,
          color_style: form.colorStyle,
          darken_header_image: form.darkenHeaderImage,
          font_style: form.fontStyle,
          header_image: form.headerImage,
          incidents_frame_style: form.incidentsFrameStyle,
          incidents_state_style: form.incidentsStateStyle,
          links_color_style: form.linksColorStyle,
          logo_sizes: form.logoSizes,
          status_frame_style: form.statusFrameStyle,
          status_text_style: form.statusTextStyle,
          text_style: form.textStyle,
          uptime_states_color_style: form.uptimeStatesColorStyle,
          uptime_style: form.uptimeStyle,
          uptime_text_style: form.uptimeTextStyle,
          custom_css: form.customCss,
          logo_link: form.logoLink,
          updates_button_style: form.updatesButtonStyle,
          status_icons_style: form.statusIconsStyle
        }
      } else {
        if (this.statusPage.status_pages_style) {
          for (let key in this.statusPage.status_pages_style) {
            if (
              (typeof this.statusPage.status_pages_style[key] === 'string' && this.statusPage.status_pages_style[key].includes('{"')) ||
              key === 'logo' || key === 'header_image' || key === 'custom_css' || key === 'logo_link' || key === 'favicon'
            ) {
              styles[key] = JSON.parse(this.statusPage.status_pages_style[key])
            } else {
              styles[key] = this.statusPage.status_pages_style[key]
            }
          }
        }
      }
      if (Object.keys(styles).length) {
        this.logoLink = styles.logo_link
        if (!styles.font_style.collapsed) {
          this.logoText = styles.font_style.logoText
        } else {
          this.logo = styles.logo
        }
        this.headerImage = styles.header_image
        this.favicon = styles.favicon
        this.isDarkenHeaderImage = styles.darken_header_image
        this.logoStyles = { maxWidth: styles.logo_sizes.width + 'px', maxHeight: styles.logo_sizes.height + 'px' }
        const getFamily = async (family) => {
          let familyResponse = await fetch(`https://fonts.googleapis.com/css?family=${family.split(' ').join('+')}`)
          return familyResponse.text()
        }
        let fontStyles = ''
        fontStyles += await getFamily(styles.font_style.font.family)
        fontStyles += await getFamily(styles.text_style.font.family)
        fontStyles += await getFamily(styles.status_text_style.font.family)
        fontStyles += await getFamily(styles.uptime_text_style.font.family)

        let fontStylesElement = document.createElement('style')
        fontStylesElement.innerHTML = fontStyles
        document.body.appendChild(fontStylesElement)

        let statusStyles = document.createElement('style')
        statusStyles.innerHTML = `
          .StatusPage {
            background-color: #${styles.color_style.background.color} !important;
            color: #${styles.text_style.color.color} !important;
            font-family: ${styles.text_style.font.value}, serif;
          }
          ${!styles.color_style.header.collapsed ? `
          .StatusPage .StatusPageHeader {
            background-color: #${styles.color_style.header.color} !important;
            color: #${styles.text_style.color.color} !important;
          }` : ''}
          ${!styles.font_style.collapsed ? `
          .StatusPage .header-container .title {
            color: #${styles.font_style.color.color} !important;
            font-weight: ${styles.font_style.weight.value} !important;
            font-family: ${styles.font_style.font.value}, serif;
          }` : ''}
          ${styles.updates_button_style ? `
          .StatusPageHeader .header .get-updates {
            color: #${styles.updates_button_style.color.color} !important;
            ${!styles.updates_button_style.background.collapsed ? `
              background-color: #${styles.updates_button_style.background.color} !important;
            ` : ''}
            ${!styles.updates_button_style.borderColor.collapsed ? `
              border: ${styles.updates_button_style.stroke}px solid #${styles.updates_button_style.borderColor.color} !important;
            ` : ''}
          }` : ''}
          ${styles.updates_button_style.formButtonColor ? `
          .StatusPageHeader .get-updates-submit-button-color {
            background-color: #${styles.updates_button_style.formButtonColor.color} !important;
          }` : ''}
          ${styles.updates_button_style.formButtonTextColor ? `
          .StatusPageHeader .get-updates-submit-text-color {
            color: #${styles.updates_button_style.formButtonTextColor.color} !important;
          }` : ''}
          .StatusPageHeader .subscribe {
            color: #${styles.text_style.color.color} !important;
          }
          .StatusPage a {
            color: #${styles.links_color_style.general.color} !important;
          }
          .StatusPage a:hover {
            color: #${styles.links_color_style.hover.color} !important;
          }
          ${styles.status_icons_style ? `
          .StatusPage .icon-circle-all-operational {
            background-color: #${styles.status_icons_style.operational.color} !important;
          }
          .StatusPage .icon-circle-some-down {
            background-color: #${styles.status_icons_style.partialSystemsOutage.color} !important;
          }
          .StatusPage .icon-circle-all-down {
            background-color: #${styles.status_icons_style.majorSystemsOutage.color} !important;
          }
          .StatusPage .MonitorBadgeStatus.online {
            background-color: #${styles.status_icons_style.operational.color} !important;
          }
          .StatusPage .MonitorBadgeStatus.offline {
            background-color: #${styles.status_icons_style.majorSystemsOutage.color} !important;
          }` : ''}
          .tippy-content {
            background-color: #${styles.color_style.background.color} !important;
            color: #${styles.text_style.color.color} !important;
          }
          .StatusPage .overall-status {
            background-color: #${styles.status_frame_style.background.color} !important;
            color: #${styles.status_text_style.color.color} !important;
            border-radius: ${styles.status_frame_style.roundness.value}px !important;
            font-family: ${styles.status_text_style.font.value}, serif;
            ${!styles.status_frame_style.borderColor.collapsed ? `
              border: ${styles.status_frame_style.stroke}px solid #${styles.status_frame_style.borderColor.color} !important;
            ` : ''}
          }
          .StatusPage .no-incidents, .IncidentsItem {
            background-color: #${styles.incidents_frame_style.background.color} !important;
            color: #${styles.text_style.color.color} !important;
            border-radius: ${styles.incidents_frame_style.roundness.value}px !important;
            ${!styles.incidents_frame_style.borderColor.collapsed ? `
              border: ${styles.incidents_frame_style.stroke}px solid #${styles.incidents_frame_style.borderColor.color} !important;
            ` : ''}
          }
          .StatusPage .no-maintenances {
            background-color: #${styles.incidents_frame_style.background.color} !important;
            color: #${styles.text_style.color.color} !important;
            border-radius: ${styles.incidents_frame_style.roundness.value}px !important;
            ${!styles.incidents_frame_style.borderColor.collapsed ? `
              border: ${styles.incidents_frame_style.stroke}px solid #${styles.incidents_frame_style.borderColor.color} !important;
            ` : ''}
          }
          .StatusPage .IncidentsItem .IncidentStatus {
            border-radius: ${styles.incidents_state_style.roundness.value}px !important;
          }
          .StatusPage .IncidentsItem .IncidentStatus.resolved {
            background-color: #${styles.incidents_state_style.resolved.color} !important;
          }
          .StatusPage .IncidentsItem .IncidentStatus.update {
            background-color: #${styles.incidents_state_style.update.color} !important;
          }
          .StatusPage .IncidentsItem .IncidentStatus.exploring {
            background-color: #${styles.incidents_state_style.exploring.color} !important;
          }
          ${styles.incidents_state_style.identified ? `
          .StatusPage .IncidentsItem .IncidentStatus.identified {
            background-color: #${styles.incidents_state_style.identified.color} !important;
          }` : ''}
          .StatusPage .IncidentsItem .posted-at {
            color: #${styles.text_style.color.color} !important;
            opacity: 0.5 !important;
          }
          .StatusPage .maintenance-item .posted-at {
            color: #${styles.text_style.color.color} !important;
            opacity: 0.5 !important;
          }
          .StatusPage .incident-history {
            color: #${styles.text_style.color.color} !important;
          }
          .StatusPage .uptime-title {
            color: #${styles.text_style.color.color} !important;
          }
          .StatusPage .rect {
            background-color: #${styles.uptime_states_color_style.operational.color} !important;
            ${!styles.uptime_style.roundness.collapsed ? `
              border-radius: ${styles.uptime_style.roundness.value}px !important;
            ` : ''}
          }
          .StatusPage .rect.empty {
            background-color: #${styles.uptime_states_color_style.unavailable.color} !important;
          }
          .StatusPage .rect.danger  {
            background-color: #${styles.uptime_states_color_style.investigation.color} !important;
          }
          .StatusPage .monitor-name {
            color: #${styles.text_style.color.color} !important;
          }
          .StatusPage .MonitorBadgeUptime {
            color: #${styles.uptime_text_style.color.color} !important;
            font-family: ${styles.uptime_text_style.font.value}, serif;
          }
          ${styles.custom_css}
        `
        document.body.appendChild(statusStyles)

        if (this.favicon) {
          var links = document.querySelectorAll('link[rel~=\'icon\']')
          for (let i = 0; i < links.length; i++) {
            links[i].href = this.favicon
          }
        }
      }
    },
    async loadSnapshots () {
      const data = await statusPagesApi.getSnapshots(this.statusPage.slug)

      let monitors = this.statusPage.monitors.slice()

      monitors = monitors.filter(monitor => {
        return data.find(i => i.monitor_id === monitor.id)
      }).map(monitor => ({
        ...monitor,
        snapshots: data.find(i => i.monitor_id === monitor.id).snapshots
      }))

      this.monitors = monitors
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .StatusPage {
    min-height: 100vh;
  }
  .container {
    max-width: 984px;
  }

  h1.uptime-title {
    font-size: 27px;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: -0.021em;
    margin-bottom: 40px;

    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 500;
      opacity: .6;
      letter-spacing: -0.017em;
    }
  }
</style>
